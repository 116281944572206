<template>
	<div class="error">
		<div>
			<img src="@/assets/imgs/error.png" alt="哎呀，页面走丢啦" />
			<div>
				<el-button round @click="returnHome">返回首页</el-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'noFound',
	methods: {
		// 返回首页
		returnHome() {
			this.$router.push({
				path: '/pages',
			});
		},
	},
};
</script>

<style scoped lang="scss">
.error {
	height: 100%;
	background-color: white;
	text-align: center;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
</style>
